<template>
  <div>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      class="ma-0 rounded-t-0"
      type="info"
      text
    >
      <div
        v-for="(schoolClass, key) in itemsGrouped"
        :key="'schoolClassOptions' + key"
      >
        <p>
          <b>{{ key }}</b> –
          {{ schoolClass.filter((el) => el.hasConfirmed).length }} von
          {{ schoolClass.length }} Schüler*innen haben die Wahl bestätigt.
          <v-progress-linear
            color="info"
            :value="
              (100 * schoolClass.filter((el) => el.hasConfirmed).length) /
              schoolClass.length
            "
          ></v-progress-linear>
        </p>
      </div>
      Du kannst Änderungen bis am <DateValue :value="choice.endDate" long /> um
      {{ choice.endTime }} nachmelden.
    </v-alert>
    <v-card flat class="rounded-t-0" :loading="loading">
      <v-divider />
      <v-card-actions>
        <v-btn text block small :to="{ name: 'OptionalSchoolClass' }"
          >zur Wahl</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>
<script>
import DateValue from "common/components/DateValue.vue";
import { groupBy } from "lodash";

export default {
  components: { DateValue },
  data() {
    return {
      loading: false,
      choice: {},
    };
  },
  computed: {
    itemsGrouped() {
      return groupBy(
        this.choice.studentItems,
        (el) => el.student.schoolClass.code
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.choice = await this.apiList({ resource: "optional/choice" });

      if (this.choice.studentItems.length > 0) {
        if (this.choice.studentItems.some((el) => el.enabled)) {
          this.$emit("loaded");
        }
      }
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
